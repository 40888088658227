.about-logo {
  transform: translateY(-1vmin);
  max-width: 5vw;
}
.info_header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: white;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-container-base {
  background-color: red;
}
.dark {
  color: #305f72 !important;
}

.navigate-to-login-btn {
  position: absolute !important;
  left: 0 !important;
  background-color: #305f72 !important;
  padding: 2.5px 20px !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  margin-bottom: auto !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: start !important;
  transform: translate(7.5vmin, 7.5vmin);
}
.navigate-to-login-btn-ar {
  position: absolute !important;
  right: 10 !important;
  background-color: #305f72 !important;
  /* background-color: grey !important; */
  padding: 2.5px 20px !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  margin-bottom: auto !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: start !important;
  transform: translate(0vmin, 7.5vmin);
}

.navigate-to-login-btn-text {
  /* transform: translateY(0.3vh); */
  font-size: 14px !important;
  color: white !important;
}

@media (max-width: 768px) {
  .about-logo {
    max-width: 15vw;
  }
}
