.temp1 {
  background-image: linear-gradient(#305f72, #9ebad9);
}

.temp2 {
  background-color: #305f72;
  /* background-image: linear-gradient(#305F72, #9ebad9); */
}

.login-stack {
  height: 65vh !important;
  /* background-color: red !important; */
  width: 90vw !important;
  justify-content: center;
  align-items: center;
  display: flex;
}
