/* login.css */
.login-container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.login-base {
  height: 100vh !important;
  background: linear-gradient(0deg, #09949d, #305f72);
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

[dir="rtl"] .MuiFormLabel-root {
  left: auto;
  right: auto;
  transform-origin: top right;
  text-align: right;
  margin-right: 25px;
}

[dir="rtl"] .MuiOutlinedInput-notchedOutline {
  text-align: right;
}

.partner_base {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4vmin;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 0;
  text-decoration: none !important;
}

.back_base {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4vmin;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 0;
  text-decoration: none !important;
}

.partner_logo {
  margin-left: 2vw;
  color: white;
}
