.bottom-nav-base {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 3px !important;
  right: 0;
  background-color: white;
}

.reminder-base {
  color: rgb(255, 255, 255);
  background-color: rgb(48, 95, 114);
  padding: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.reminder-btn {
  background-color: white !important;
  width: 30% !important;
  margin: 10px !important;
  color: rgb(48, 95, 114) !important;
  padding: 0px !important;
  border-radius: 10px !important;
}

/* Bottom Navigation Bar */
.bottom-nav {
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* padding: 10px; */
}

.learn-button {
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-placeholder {
  width: 30px;
  height: 30px;
}
