.cont {
  background: #305f72;
  text-align: right;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.header {
  height: 10vh;
  width: 85vw;
  /* position: absolute;
  top: 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header-summary {
  height: 8vh;
  width: 85vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* 
* {
  user-select: none;
}

#root > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app {
  overflow: hidden;
}

.app > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

h1 {
  font-family: "Damion", cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
}

h2 {
  color: #fff;
} */
.lmao {
  text-align: center !important;
  /* color: #909090 !important; */
  border-radius: 5px;
  padding: 1vmin 2vmin;
  /* margin: 0.5vmin; */
  width: 80vw;
  margin: auto;
  /* max-width: 260px; */
  overflow: hidden;
  white-space: nowrap;
}
.swipe {
  position: fixed;
}
.answer {
  background-color: #9198e5;
  margin: 5%;
  padding: 3%;
  border-radius: 10px;
}
.remove_card {
  display: none;
}
.cardContainer {
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* max-width: 260px; */
  /* position: fixed !important; */
  /* height: 300px; */
  transform: translateY(40vh) !important;
}
.cardContainer-dt {
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 260px;
  height: 300px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: center;
}

.card {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  position: relative;
  background-color: white;
  width: 85vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  min-height: 60vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 7.5px;
  background-size: cover;
  background-position: center;
}
.card-dt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  background-color: white;
  width: 35vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  height: 62.5vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 7.5px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.illustration {
  object-fit: contain;
  aspect-ratio: 1;
  margin: 10px;
}

.small {
  width: 50%;
}

.medium {
  width: 70%;
}

.large {
  width: 90%;
}

.infoText {
  width: 100%;
  font-size: 5vw;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.infoText-dt {
  width: 100%;
  font-size: 2vw;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.bonusText {
  width: 100%;
  font-size: larger;
  justify-content: center;
  margin: 40px;
  font-weight: 700;
  display: flex;
  color: #ffc700;
  animation-name: popup;
  animation-duration: 800ms;
}

.popout {
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  position: absolute;
  bottom: 0;
  margin: 20px;
  display: flex;
  flex-direction: row;
  width: 85vw;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
}

/* @media (max-width: 625px) {
  .buttons {
    flex-direction: column;
  }
} */

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #9198e5;
  transition: 200ms;
  margin: 10px;
  font-weight: bolder;
  width: 160px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  code {
    background-color: #f0f0f0;
    color: #616161;
    font-size: 1rem;
  }

  pre {
    background: #f0f0f0 !important;
    color: #000;
    width: 90% !important;
    margin: auto;
    padding: 0.75rem 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    border-bottom-right-radius: 10px;
    box-shadow: 2px 2px #888888;
    /* border: 4px solid gainsboro !important; */
  }
}

code {
  background-color: #f0f0f0;
  color: #616161;
  font-size: 1rem;
}

pre {
  background: #f0f0f0 !important;
  color: #000;
  width: 90% !important;
  margin: auto;
  padding: 0.75rem 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  border-bottom-right-radius: 10px;
  box-shadow: 2px 2px #888888;
  /* border: 4px solid gainsboro !important; */
}

.formattingBtn {
  margin: 1vmin !important;
}

.answer {
  border: 0px;
}
.unpicked-answer {
  background-color: #f0f0f080;
  box-shadow: 0 3px 5px #9ebad9b3;
}
.picked-answer {
  background-color: #9ebad9;
  box-shadow: 0 3px 5px #9ebad9b3;

  border: 3px solid #9ebad9 !important;
  opacity: 1 !important;
}
.picked_indicatior_icon {
  background-color: #9198e5;
  -webkit-box-shadow: inset 0px 0px 0px 2px #fff;
  -moz-box-shadow: inset 0px 0px 0px 2px #fff;
  box-shadow: inset 0px 0px 0px 2px #fff;
}
.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.correct_indicator_icon {
  background-color: #4cb04a !important;
  border: 2px solid #4cb04a !important;
  /* size: 5vw !important; */
}
.incorrect_indicator_icon {
  background-color: #fb262d !important;
  border: 2px solid #fb262d !important;
  font-weight: 700 !important;
  /* size: 5vw !important; */
}
.correct-answer {
  border: 3px solid #4cb04a !important;
  box-shadow: 0 3px 5px #9ebad9b3;
}
.incorrect-answer {
  border: 3px solid #fb262d !important;
  box-shadow: 0 3px 5px #9ebad9b3;
}
.css-5xe99f-MuiLinearProgress-bar1 {
  opacity: 80%;
  background-color: #9ebad9 !important;
}

.alarm_icon {
  width: 8vw;
  margin: 1vw;
  transform: translateY(-5px);
}

.timer_countdown_base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform: translateX(5px);

  align-items: center;
}

.code-style {
  background-color: #f0f0f0;
  color: #616161;
  font-size: 1rem;
}

.pre-style {
  background: #f0f0f0 !important;
  /* color: red !important; */
  width: 90% !important;
  margin: auto;
  padding: 0.75rem 1rem;
  /* font-size: 3rem !important; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  border-bottom-right-radius: 10px;
  box-shadow: 2px 2px #888888;
  /* border: 4px solid gainsboro !important; */
}

.pre-style code {
  background-color: #f0f0f0 !important;
  color: #616161 !important;
  font-size: 1rem !important;
}
