/* Top Navigation Bar */
.top-nav {
  background-color: white;
  z-index: 1000 !important;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  padding-bottom: 0px !important;
}

.top-nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.points-display {
  background-color: #f6f6f6;
  border-radius: 40px !important;
  padding: 0vmin 4vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.user-badge {
  width: 7vw !important;
  border-radius: 40px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.top-nav button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.MuiMenu-paper {
  width: 200px !important; /* You can adjust the width as needed */
}

.MuiMenuItem-root {
  padding: 10px;
}

/* Make sure the chevron icons are aligned */
.MuiMenuItem-root > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Menu item content styling */
.menu-item-content {
  display: flex;
  align-items: center;
}

.menu-item-content .menu-icon {
  width: 20px; /* Adjust the size of the icon */
  height: 20px; /* Adjust the size of the icon */
  margin-left: 10px; /* Space between the icon and text */
}

/* Styling for settings menu items */
.settings-menu-item {
  direction: rtl;
  display: flex;
  justify-content: space-between !important;
  align-items: center; /* Ensures vertical alignment of icon and text */
}

/* Styling for important info menu items */
.menu-item {
  direction: rtl;
}

/* Ensure chevron alignment */
.MuiCollapse-root {
  margin-left: 10px;
}