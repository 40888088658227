.definition_base {
  width: 80%;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  direction: rtl;
  padding: 1.5vmax;
  border-radius: 10px;
}

.games_container {
  overflow-y: auto;
  height: calc(100vh - 100px); /* Adjust height as needed */
  padding: 20px;
  position: relative;
  margin-top: 10px;
}

.table_row {
  width: 50px !important;
  background-color: red !important;
}

.games_banner_text {
  /* background-color: blue; */
  font-size: 10vmin;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-10vmin, -2vmin);
}

.game_card {
  margin-top: 5vmin;
  padding: 20px;
  margin-bottom: 2vmin;
}

.game_card_text {
  text-align: center;
  font-size: 1.8rem !important;
  /* color: #305f72; */
}

.wordle {
  text-align: center;
  margin-top: 50px;
}

input {
  padding: 5px;
  margin-right: 10px;
  text-transform: uppercase;
}

.wordle_title {
  padding: 2vmin;
  color: #305f72;
}
