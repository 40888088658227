.whyMaha {
  width: 80%;
  /* padding-top: 2vh; */
  /* padding-bottom: 4vh; */
  margin: auto;
}
.whyMaha h2 {
  color: #305f72;
}
.whyMahaIcon {
  width: 12.5vw !important;
}
.whyMahaGrid {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 4vh; */
  padding-bottom: 4vh;
}
.whyMahaImageBase {
  width: 50%;
}
.whyMahaImage {
  padding: 2vw;
  width: 90%;
  object-fit: contain;
  height: 90%;
}
.whyMahaIcons {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.whyMahaIconTitle {
  display: flex;
  align-items: center;
}
.whyMahaIconTitle-ar h4 {
  margin-right: 2vmin !important;
}
.whyMahaIconCard {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 90%;
}

@media (max-width: 1024px) {
  .whyMahaGrid {
    width: 100%;
    flex-direction: column;
  }

  .whyMahaIcons {
    margin: auto;
    width: 90vw;
  }

  .whyMahaImageBase {
    text-align: center;
    width: 100%;
  }
  .whyMahaIconCard p {
    width: 100%;
  }
}
