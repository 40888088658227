.summaries-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  position: relative;
  max-width: 100vw - 40px !important;
  margin-top: 10px;
  margin-bottom: 10vh;
}
.card-stack {
  width: 100% !important;
  height: 120px !important;
}
.summaries-container-dt {
  overflow-y: auto;
  width: 90%;
  height: 80vh;
  margin: auto;
  margin-top: 5vmin !important;
  padding: 20px;
  display: flex;
  overflow-y: hidden !important;
  justify-content: space-evenly;
}

.current-summary-dt {
  width: 40% !important;
  height: 100% !important;
  overflow-y: hidden;
  padding: 1vmin;
}

.past-summaries-container-dt {
  width: 55% !important;
  height: 100% !important;
  padding: 1vmin;
  overflow-y: scroll;
}

.current-summary-banner {
  width: 100% !important;
  height: 5vh !important;
  object-fit: contain !important;
}

.puzzle-illustration {
  object-fit: contain !important;
  height: 120px !important;
  margin: auto !important;
}
.summary-border {
  margin-bottom: 2vh !important;
  /* border: 0.5px solid #9ebad9; */
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important;

  padding: 0px !important;
}

.marhey {
  font-family: "Marhey" !important;
}

.tajawal {
  font-family: "Tajawal" !important;
}

.search-container {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  transform: translateX(-5vw);
  margin-top: 2vh;
  background: url("./crossword_bg_3.png") !important;
  /* background-color: red !important; */
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position-x: right !important;
}

.serach-card {
  transform: translate(-5vw, 0.5vh);
  width: 70%;
  height: 10vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.icon-search {
  width: 30%;
}

.chevron_base {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.archive_base {
  width: 90%;
  display: flex;
  flex-direction: row;
  padding: 0px !important;
  height: 100%;
}

.archive_content {
  width: 90%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
