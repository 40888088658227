.toggle-switch {
  width: 50vw;
  height: 5vh;
  /* max-width: 50vw; */
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 898;
  /* position: absolute; */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.toggle-switch-dt {
  width: 30vw;
  height: 6vh;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 898;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.fontToggle {
  height: 4.5vh;
  /* width: 20vw; */
  display: flex;
  font-family: "Marhey" !important;
  justify-content: center;
  align-items: center;
}

.toggleButton {
  width: 100% !important;
  border-radius: 7px;
  /* margin: 1vw !important; */
}
.activeToggle {
  background-color: white !important;
  height: 65% !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
