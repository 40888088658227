.summary-container {
  overflow-y: auto;
  margin-bottom: 10vh;
}

.summaries-container-dt {
  overflow-y: auto;
  width: 90%;
  margin: auto;
  padding: 20px;
}

.current-summary-dt {
  width: 45vw;
  margin: auto;
}

.current-summary-banner {
  width: 100% !important;
  height: 5vh !important;
  object-fit: contain !important;
}

.current-button {
  background: linear-gradient(0deg, #09949d, #305f72);
  box-shadow: 0 5px 3px 0 #024e51;
}
/* .summary-border {
  border-top: 3px solid #9ebad9;
} */
