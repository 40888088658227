/* Overall page styles */
.rewards-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  position: relative;
  max-width: 100vw;
  margin-top: 10px;
  margin-bottom: 10vh;
}

/* Rewards listing */
.rewards-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
  direction: rtl;
}

.cta-text {
  transform: translateY(0.3vh);
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
}

/* Reward card layout */
.reward-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 2.5vh;
  overflow: hidden;
  width: 95vw;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reward-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.reward-steps-line {
  direction: rtl;
}

.reward-steps-line-bold {
  margin-top: 1.5vh;
  font-weight: 500;
}

.reward-steps-title {
  font-size: 1.7rem;
  text-align: center;
}
.dotted {
  margin-bottom: 1vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6 !important;
  overflow-x: hidden;
  display: block !important;
  width: 100% !important;
  white-space: nowrap !important;
}

/* Reward card details */
.reward-card-details {
  padding: 10px;
  background-color: #f0f4f8;

  /* background-color: #f0f0f0; */
}

.reward-organization {
  font-size: 20px !important;
  font-weight: bold;
  color: #333;
  text-align: right;
}

.reward-name {
  font-size: 14px !important;
  font-weight: 400;
  color: #333;
  text-align: right;
  margin-top: 5px;
}

.reward-description {
  font-size: 14px !important;
  font-weight: 300;
  color: #333;
  text-align: right;
  margin-top: 5px;
}

.reward-price,
.reward-validity {
  font-size: 0.8rem;
  font-weight: 300;
  color: #333;
  text-align: right;
  font-style: italic;
}

.reward-validity-margin {
  margin-bottom: 20px !important;
}

/* Image section */
.reward-card-image {
  width: 100%;
  height: 40vw;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reward-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reward-card-header {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}
/* Button styles */
.current-button-2 {
  margin-top: 2vh !important;
  margin-bottom: 2vh !important;
  background-color: #000 !important;
}

.current-button-3 {
  background-color: #e8e8e8 !important;
  /* background: linear-gradient(0deg, #09949d, #305f72); */
  box-shadow: 0 1px 3px 0 #000;

  padding: 5px 15px !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  margin-bottom: auto !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: start !important;
  transform: translate(0.3vw, 0.3vh);
}

.view-steps-button {
  margin-top: 1.7vh !important;
  background-color: #fff !important;
  color: #333 !important;
  padding: 5px 15px !important;
  border-radius: 25px !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: start !important;
}
