.score-root {
  overflow-y: auto;
  height: calc(100vh - 100px); /* Adjust height as needed */
  /* padding: 20px; */
}

.score {
  height: 80%;
}

.score-dt {
  /* width: 50vw; */
  height: 80%;
}
.score-base {
  width: 80%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.dark {
  color: #305f72 !important;
}

.primary {
  color: #9ebad9 !important;
}
.padding {
  height: 10vmin;
}

.coin-base {
  width: 100%;
  /* height: 10px; */
  position: absolute;
  top: 60%;
}

.coin {
  position: absolute;
  /* border-radius: 50%; */
  /* animation: moveToTopLeft 2s forwards; */

  /* transform: translate(45vw, 0); */
}

@keyframes moveToTopLeft {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-65vw, -100vh);
  }
}

/* Add staggered delays */
.coin:nth-child(1) {
  animation-delay: 0.5s;
}
.coin:nth-child(2) {
  animation-delay: 0.75s;
}
.coin:nth-child(3) {
  animation-delay: 1s;
}
