.useCases {
  width: 100%;
  padding-top: 2vh;
  padding-bottom: 4vh;
  background-color: #b5c9e0;
}
.useCasesBase {
  margin: auto;
  width: 80%;
}

.useCasesBase h2 {
  color: #305f72;
}
.useCasesGrid {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 4vh;
  padding-bottom: 4vh; */
}
.useCaseImageBase {
  width: 50%;
  text-align: center;
}
.useCaseImage {
  width: 90%;
  object-fit: contain;
  height: 90%;
}

.useCasesCard {
  background-color: white;
  width: 90%;
  object-fit: contain;
  height: 90%;
  margin: auto;
  max-width: 280px;
  border-radius: 10px;
  border: 3px solid black;
  margin-bottom: 4vh;
  height: 250px;
}
.useCasesCardHeader {
  background-color: #f1aa48;
  /* background-color: #305f72; */
  height: 3vh;
  border-bottom: 3px solid black;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 1vmin;
  padding-right: 1vmin;
}

.useCasesCardHeaderDot {
  width: 7.5px;
  background-color: black;
  height: 7.5px;
  margin: 5px;
  border-radius: 100%;
}

.useCasesCardBase {
  padding: 4vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.useCasesCardDescription {
  /* padding-top: 2vmin; */
  /* text-align: justify;
  text-justify: inter-word; */
  padding-left: 3vmin;
  padding-right: 3vmin;
  /* width: 80%; */
  /* word-break: break-all; */
  /* hyphens: auto;
  word-wrap: break-word; */
}

.ar {
  direction: rtl !important;
  text-align: right !important;
}
@media (max-width: 768px) {
  .useCasesGrid {
    width: 100%;
    flex-direction: column;
  }

  .useCaseImageBase {
    text-align: center;
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
}
