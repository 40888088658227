.c22ont {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  /* background: linear-gradient(#fff, #999); */
  background: #305f72;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.h2eader {
  height: 10vh;
  width: 85vw;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header-summary {
  height: 8vh;
  width: 85vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* 
* {
  user-select: none;
}

#root > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app {
  overflow: hidden;
}

.app > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

h1 {
  font-family: "Damion", cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
}

h2 {
  color: #fff;
} */
.lmao {
  text-align: center !important;
  /* color: #909090 !important; */
  border-radius: 5px;
  padding: 1vmin 2vmin;
  /* margin: 0.5vmin; */
  width: 80vw;
  margin: auto;
  /* max-width: 260px; */
  overflow: hidden;
  white-space: nowrap;
}
.answer {
  background-color: #9198e5;
  margin: 5%;
  padding: 3%;
  border-radius: 10px;
}

.cardContainer-dt {
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 260px;
  height: 300px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: center;
}
.card-dt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  background-color: white;
  width: 35vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  height: 62.5vh;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 7.5px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.illustration {
  object-fit: contain;
  aspect-ratio: 1;
  margin: 10px;
}

.small {
  width: 50%;
}

.medium {
  width: 70%;
}

.large {
  width: 90%;
}

.infoText {
  width: 100%;
  font-size: 5vw;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.infoText-dt {
  width: 100%;
  font-size: 2vw;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.bonusText {
  width: 100%;
  font-size: larger;
  justify-content: center;
  margin: 40px;
  font-weight: 700;
  display: flex;
  color: #ffc700;
  animation-name: popup;
  animation-duration: 800ms;
}

.popout {
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  position: absolute;
  bottom: 0;
  margin: 20px;
  display: flex;
  flex-direction: row;
  width: 85vw;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
}

/* @media (max-width: 625px) {
  .buttons {
    flex-direction: column;
  }
} */

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #9198e5;
  transition: 200ms;
  margin: 10px;
  font-weight: bolder;
  width: 160px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: #f0f0f0;
    color: #616161;
  }

  pre {
    background: #f0f0f0 !important;
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background-color: #f0f0f0 !important;
      font-size: 2.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

pre {
  background: #f0f0f0 !important;
  color: #000;
  width: 90% !important;
  margin: auto;
  padding: 0.75rem 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  border-bottom-right-radius: 10px;
  box-shadow: 2px 2px #888888;
  /* border: 2px solid gainsboro !important; */
}

.formattingBtn {
  margin: 1vmin !important;
}

.answer {
  border: 0px;
}
.unpicked-answer {
  background-color: rgba(240, 240, 240, 0.5);
}
.picked-answer {
  background-color: #9ebad9;
  opacity: 0.5;
}
.correct-answer {
  border: 2px solid #4cb04a;
}
.incorrect-answer {
  border: 2px solid #fb262d;
}
