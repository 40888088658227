.expandable-circle {
  position: relative;
}

.circle {
  margin: auto;
  width: 80vw;
  border-radius: 1250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #f0f4f8;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  user-select: none !important;
}

.circle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desc_base {
  max-height: 20vw;
  overflow: hidden;
  width: 80%;
  margin: auto;
  font-weight: 300 !important;
  margin-top: -2vh;
}

.logo {
  width: 25vw;
}

.text-content {
  text-align: center;
  font-size: 4.5vw;
  font-weight: 700;
}

.finished-tutorials {
  color: #305f72;
  font-size: 14.5px;
}

.expanded-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: height 3s ease-in-out;
  opacity: 0;
}

.open {
  opacity: 1;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
  justify-content: center !important;
}

.item-base {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25vh;
  z-index: 213;
}

.item-base-single {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25vh;
  z-index: 213;
}

.item {
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  background-color: lightgray;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: opacity 0.3s ease-in-out;
}

.scrolling-container {
  overflow: hidden;
  text-align: center;
  flex-wrap: wrap;
  width: 55%;
  font-size: 3.5vw;
  font-weight: 400;
  margin-bottom: 5vh;
}

.scrolling-container_2 {
  overflow: hidden;
  font-size: 4vw;
  font-weight: 700;
  text-align: center;
  flex-wrap: wrap;
  margin-top: 1vh;
}

.lines-svg {
  position: absolute;
  top: 0px;
  height: 100%;
  pointer-events: none;
  transform: translateY(-7.5vh);
}

.scrolling-container h5 {
  display: inline-block;
}

.chevron {
  display: inline-block;
  font-size: 1.5rem;
  transition: transform 0.3s ease; /* Smooth rotation */
}

.chevron.expanded {
  transform: rotate(-180deg); /* Rotate by -180 degrees when expanded */
}

@keyframes scroll-text {
  100% {
    transform: translateX(-100%);
  }
}
