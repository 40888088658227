.summaries-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  position: relative;
  max-width: 100vw - 40px !important;
  margin-top: 10px;
  margin-bottom: 10vh;
}

.summaries-container-dt {
  overflow-y: auto;
  width: 90%;
  margin: auto;
  padding: 20px;
}
/* .streak-header {
  width: 50%;
  margin-left: auto;
  background-color: red;
}
.streak-subtitle {
  background-color: blue;
} */
.current-summary-dt {
  width: 45vw;
  margin: auto;
}

.nnnn {
  /* background-color: red !important; */
  width: 50px !important;
}

.current-summary-banner {
  width: 100% !important;
  height: 5vh !important;
  object-fit: contain !important;
}

.current-button {
  background: linear-gradient(0deg, #09949d, #305f72);
  box-shadow: 0 5px 3px 0 #024e51;
}
.current-button-2 {
  background: linear-gradient(0deg, #09949d, #305f72);
  box-shadow: 0 5px 3px 0 #024e51;
  padding: 5px 15px !important;
  border-radius: 25px !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: start !important;
}
/* .summary-border {
  border-top: 3px solid #9ebad9; */
/* } */

.app-container {
  padding: 16px;
  background-color: #f8f8f8;
}

.cards-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-s {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #fff;
  border-bottom: 1px solid #ddd;
}

.temmp {
  height: 7.5vh;
  margin-bottom: 10px;
}

.streak-tracker {
  background-color: #ffffff;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  margin-top: 12vmin;
  margin-bottom: 10vmin;
  padding-bottom: 1vmin;
}

.streak-title {
  font-size: 30px;
  /* color: #fff; */
  transform: translateY(1vh);
  margin-bottom: 10px;
  /* text-shadow: #000 1px 1px 1px; */
  text-align: center;
}
.streak-title2 {
  font-size: 20px;
  /* color: #fff; */
  transform: translateY(1vh);
  margin-bottom: 10px;
  /* text-shadow: #000 1px 1px 1px; */
  text-align: center;
}

.streak-title-no-shadow {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
  margin-bottom: 0;
}

.streak-subtitle {
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-1.5vh) !important;
  position: absolute;
  top: 0;
  left: 20vw;
}

.days-container {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin: 10px 0;
}
.streak-line {
  height: 2px;
  width: 85%;
  margin: auto;
  position: absolute;
  transform: translate(5vmin, 7.5vmin);
  z-index: 0 !important;
  background-color: #f0f4f8;
}

.streak-line-milestones {
  /* height: 2px; */
  width: 85%;
  border: none;
  border-top: 2px dotted #f0f4f8;
  /* border-top: 2px dotted red; */
  margin: auto;
  position: absolute;
  transform: translate(5vmin, -6vmin);
  z-index: 0 !important;
  /* background-color: red; */
  /* background-color: #f0f4f8; */
}
.day-coin {
  z-index: 999 !important;
}
.coin-text-base {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-1vmin);
  background-color: white;
}
.coint-text {
  background-color: white;
  /* padding-right: 1vmin; */
  transform: translate(0.25vmin, 0.6vmin);
}
.starting-point {
  width: 12px;
  position: absolute;
  height: 12px;
  background-color: #f0f4f8;
  border-radius: 50%;
  /* background-color: #e8e8e8 !important; */
  transform: translate(3.5vmin, -7.35vmin);
}
.day-item {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 40px;
  margin-bottom: 5px;

  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 2vmax;
  color: #305f72;
  direction: rtl;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.dActive {
  background-color: #4cb04a !important;
}

.day-item.active {
  background-color: #d1ecf1;
  color: #007bff;
  font-weight: bold;
  border: 2px solid #007bff;
}

.streak-message {
  font-size: 14px;
  color: #305f72;
  margin-top: -2vmin;
  background-color: #f0f4f8;
  margin-bottom: 4vmin;
  width: fit-content;
  margin-left: auto;
  padding: 10px;
  margin-right: auto;
  border-radius: 5px;
  /* box-shadow: 0 2px 8px rgb(0 0 0 / 10%); */
  text-align: center;
  bottom: -10px;
  position: relative;
}

.streak-message2 {
  margin-bottom: -1vh;
}

.points {
  padding-left: 10px;
  padding-right: 10px;

  background-color: white;
}

.streak-card {
  background-color: white;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  text-align: right;
  /* width: 90vw; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.points-card,
.medals-card {
  background-color: #ffffff;
  border-radius: 12px;
  /* padding: 10px 20px; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 48%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* max-height: 25vmax !important; Ensures both cards are of equal height */
}

.card-header {
  min-height: 40%;
  background-color: #f0f4f8;
  border-radius: 12px 12px 0 0; /* Rounded corners at the top */
  /* height: 40px; Height of the grey header */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding-bottom: 10px;
  /* Space between header and content */
}

.header-icon {
  font-size: 18px;
  color: #6c757d;
}

.points-title,
.medals-title {
  /* font-size: 16px; */
  color: #4a4a4a;
  /* margin-bottom: 10px; */
}
.lmao {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.points-value {
  font-size: 12px;
  color: black;
  /* font-weight: bold; */
  padding: 3px;
  margin: 10px;
  border-radius: 5px;
  border: #000 0.5px solid;
  transform: translate(-10px, -5px);
  /* margin-bottom: 10px; */
}

.points-list,
.medals-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}

.points-list li {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 6px 20px;
}

.medals-list li {
  display: flex;
  text-align: right;
  flex-direction: column;
  /* justify-content: space-between; */
  font-size: 12px;
  margin: 6px 6px;
}

.item-label,
.medal-label {
  color: #6c757d;
}

.item-value,
.medal-points {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.bottom-buttons-container {
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  margin-bottom: 100px;
  /* gap: 5px; Space between the buttons */
  /* padding: 10px 20px; Adjust padding to match your layout */
}

.bottom-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Button background */
  /* border: 1px solid #e0e0e0; Border around the button */
  border-radius: 8px 8px 0 0; /* Rounded corners */
  padding: 10px 15px; /* Padding inside the button */
  font-size: 16px; /* Text size */
  color: #333; /* Text color */
  cursor: pointer; /* Pointer on hover */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: none;
  transition: background-color 0.2s;
}

.bottom-button:hover {
  background-color: #f9f9f9; /* Slightly different color on hover */
}

.bottom-button-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Button background */
  /* border: 1px solid #e0e0e0; Border around the button */
  border-radius: 0 0 8px 8px; /* Rounded corners */
  padding: 10px 15px; /* Padding inside the button */
  font-size: 16px; /* Text size */
  color: #333; /* Text color */
  cursor: pointer; /* Pointer on hover */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: none;
  transition: background-color 0.2s;
}

.bottom-button-2:hover {
  background-color: #f9f9f9; /* Slightly different color on hover */
}

.button-text {
  flex: 1; /* Ensures text takes up most space */
  color: #305f72;
  text-align: right; /* Align text to the right */
  margin-right: 10px; /* Space between text and arrow */
}

.button-arrow {
  font-size: 18px; /* Size of the arrow */
  color: #007bff; /* Arrow color, adjust as needed */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 85% !important;
  text-align: center;
  height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.close-button {
  background-color: #ebedf5;
  border-radius: 50%;
  width: 7vmin;
  height: 7vmin;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #305f72;
}

.streak-icon {
  position: absolute;
  width: 16vmin;
  left: 0;
  transform: translate(4.5vmin, -18.5vmin);
}

.tick_icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  transform: rotate(15deg);
  flex-direction: column;
  justify-content: center;
}

.modal-headline,
.modal-p {
  text-align: justify;
  direction: rtl;
  text-justify: inter-word;
  font-size: 0.9rem;
}

.modal-headline {
  font-size: 1rem;
  font-weight: bold;
}

.streak-info {
  position: absolute;
  width: 15vmin;
  left: 0;
  transform: translate(5.2vmin, -12.5vmin);
}

.days-text {
  transform: translate(0, -1.5vmin);
}

.day-label {
  transform: translate(0, 0.5vmin);
}
.day-number {
  transform: translate(0, -0.5vmin);
}

.dialog-title {
  color: #305f72;
  font-size: 1.75rem;
}

.user_banner_text {
  /* background-color: blue; */
  font-size: 10vmin;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-7.5vmin, 0vmin);
}

.countdown_base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); */
  padding: 3vmin;
  /* width: 100vw; */
  height: 9vh;
  /* padding: 10px; */
  /* background-color: red; */
  /* border-radius: 10px; */
  /* margin-top: -15px; */
  background: url("./confetti_2.gif");
  background-size: contain;

  /* background-color: #C7D4CD; */
  background-position: top;
  background: cover;
  margin-bottom: 5vh;
}
.countdown_base_gradient {
  background-image: linear-gradient(#305f72, #9ebad9);
  /* border-radius: 10px; */
  /* margin-left: -20px; */
}
.countdown_interval_base {
  width: 85vw;
  display: flex;
  flex-direction: row;
  transform: translateY(-1vh);
  align-items: self-end;
  justify-content: space-evenly;
}

.countdown_interval {
  padding: 5vmin;
  box-shadow: 14px 10px 4px 0px rgba(0, 0, 0, 0.6);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); */
  width: 4vw;
  border-radius: 10px;
  text-align: center;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 8vw;
  z-index: 999 !important;
}
