.toggle-switch {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.toggle-switch button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px;
}

.toggle-switch button.active {
  font-weight: bold;
}

.page-container-dt {
  overflow-y: auto;
  transition: transform 0.5s ease-in-out;
}
.learn-page {
  position: relative;
  text-align: center;
  overflow-x: hidden;
}
.tutorials-active {
  transform: translateX(0);
}

.summaries-active {
  /* transform: translateX(-100%); */
}

.banner {
  /* margin-top: 10vw; */
  width: 85vw;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 10px;
  margin-left: auto;
}
