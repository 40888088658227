/* Podium.css */
.podium-container {
  height: 90vh;
}

.podium {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 12vh;
}

.step {
  width: 20vw;
  background-color: #f6f6f6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 100%;
  border-radius: 10px;
  /* border: 1px solid black; */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.7);
}
.step-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.step-1 {
  /* background-color: #ffd700; */
  z-index: 3;
  height: 100%;
}

.step-2 {
  /* background-color: #c0c0c0; */
  z-index: 2;
  height: 70%;
}

.step-3 {
  /* background-color: #cd7f32; */
  height: 50%;
  z-index: 1;
}
