.tutorials-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  position: relative;
  max-width: 100vw - 40px !important;
  margin-top: 10px;
  margin-bottom: 10vh;
}

.tutorials-container-dt {
  overflow-y: auto;
  width: 90%;
  margin: auto;
  padding: 20px;
}

.tutorials_banner_text {
  /* background-color: blue; */
  font-size: 10vmin;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-10vmin, -5vmin);
}
