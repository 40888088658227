.custom-card {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 120px;
}

.card-media {
  width: 40% !important;
  object-fit: contain;
  border-radius: 18px;
  background-image: linear-gradient(#305f72, #9ebad9);
}

.bold-font {
  font-weight: 700 !important;
}

.card-content {
  width: 60% !important;
  padding: 10px !important;
}

.title {
  text-align: right;
  margin-top: 10px;
  font-weight: bold;
  color: #305f72;
}

.description {
  text-align: right;
  margin-top: 5px !important;
}

.text-secondary {
  color: #666;
}

.text-white {
  color: white;
}

.card-stack {
  position: relative;
  height: 180px; /* Adjust as needed for stacking effect */
}
